.image_1 {
    width: 30rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.image_2 {
  width: 50rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  transform: rotate(180deg);
}

.big-background {
  background-color: #f5f4f4;
  padding-top: 2.5rem;
  padding-bottom: 1rem;
}

.pos_block {
    display: flex;
    margin: 7rem 10rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.other_image {
    width: 30rem;
    border-radius: 2%;
    border: 0.5px solid #F09B34;
}

.bill-image {
  width: 15rem;
  border-radius: 2%;
  border: 0.5px solid #F09B34;
}

#box {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: 'Raleway';
    font-size: 2.5rem;
  }
  .gradient-border {
    --borderWidth: 3px;
    background: #1D1F20;
    position: relative;
    border-radius: var(--borderWidth);
  }
  .gradient-border:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
  
  
  @keyframes animatedgradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

.pos-foot {
    text-align: center;
}
  
.link {
  text-decoration: none;
}

.link:hover {
  color: forestgreen;
  background-color: greenyellow;
  transform: scale(1.05);
  transition-duration: 1s;
}

.link:active {
  color: orange;
}

.link:visited {
  color: blueviolet;
}

h1.pos {
  color: #F09B34;
}

h1.pay-daily {
  color: blueviolet
}

h1.neon {
  color: #f37055;
}

.pos-container {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.pos-small-title {
  text-align: left;
  padding-left: 5rem;
  text-decoration: underline;
}

.no-note {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  gap: 5rem;
  margin: 5rem;
  flex-wrap: wrap;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media only screen and (max-width: 720px) {
  .pos_block {
    margin: 1rem;
  }
}

@media only screen and (max-width: 500px) {
  .other_image {
    width: 80%;
  }

  .image_1 {
    width: 80%;
  }

  .bill-image {
    width: 50%;
  }
}

.rotate {
  transform: rotate(270deg);
}

.pos-h4 {
  text-align: center;
  margin: 3rem;
}