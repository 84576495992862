.appstore {
    width: 10rem;
    margin: 2rem;
}

.applogo {
    width: 5rem;
    border-radius: 10%;
    border: 0.5px solid white;
    margin: 2rem;
}

.flex-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 4rem;
}

.screenshot {
    width: 15rem;

    &:hover{
        transform: scale(1.1);
        transition-duration: 1s;
    }
}

.sudoku {
    background-color: lightsalmon;
    text-align: center;

    &_title {
        padding-top: 1.5rem;
    }
}

.sudokuzen {
    background-color: white;
    text-align: center;

    &_title {
        padding-top: 1.5rem;
    }
}
