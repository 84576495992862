@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
:root {
  --primaryColor: #f15025;
  --mainWhite: #fff;
  --mainBlack: #222;
  --mainGrey: #9e9e9e;
  --mainRed: #d50000;
  --mainGreen: #4caf50;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: var(--mainBlack);
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

h1, h2 {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  color: var(--mainBlack);
  letter-spacing: 5px;
}


  
.App, 
.costContainer,
.costPage  {
  margin: 0 auto;
}





