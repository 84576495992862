.item {
    background-color: var(--mainWhite);
    line-height: 1.5rem;
    padding: 10px 20px;
    margin-bottom: 1rem;
    border: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    transition: all 0.3s linear;
    

    .info {
        flex: 0 0 60%;
        display: flex;
        justify-content: space-between;

        .expense {
            margin-right: 2rem;
            text-transform: capitalize;
        }
          
        .amount {
            font-weight: 300;
            font-size: 1rem;
            color: var(--mainWhite);
            background-color: var(--primaryColor);
            border-radius: 2px;
            padding: 1px 3px;
        }
    }
}
  
.item:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transform: scale(1.05);
}
  
.clear-btn,
.edit-btn {
    background: none;
    border: none;
    font-size: 1.2rem;
    outline: none;
    cursor: pointer;
}
  
.clear-btn {
    color: var(--mainRed);
}
  
.edit-btn {
    color: var(--mainGreen);
}