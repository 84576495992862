.skills {
    text-align: center;
    line-height: 65px;
    font-size: 1.6rem;
    font-family: 'Courier New', Courier, monospace;
    padding-top: 50px;
    padding-bottom: 30px;

    .contact {
        color:orangered;
        margin: 30px;
    }

    .intro {
        margin: 30px;
    }
}

