.costPage {
  max-width: 1280px;
  width: 90%;
  background: var(--mainWhite);
  padding-bottom: 2rem;
  border-radius: 2px;
  border-style: outset;
  border-color: (--mainGrey);
}

.btn {
  text-decoration: none;
  color: var(--mainWhite);
  background-color: var(--primaryColor);
  text-align: center;
  letter-spacing: 0.5px;
  font-size: 14px;
  outline: 0;
  border: none;
  border-radius: 2px;
  display: block;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  text-transform: uppercase;
  margin: 0 auto;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: flex;
  align-items: center;
}

.btn:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.btn-icon {
  margin-left: 0.75rem;
  font-size: 1.2rem;
}

@media screen and (min-width: 601px) {
  .costContainer,
  .list,
  .alert {
    width: 85%;
  }

  .list {
    margin: 2rem auto 0 auto;
  }
}

@media screen and (min-width: 993px) {
  .costContainer,
  .list,
  .alert {
    width: 70%;
  }
  .list {
    margin: 2rem auto 0 auto;
  }
  .info {
    flex: 0 0 50%;
  }
}


.total {
  font-weight: 300;
  color: var(--mainWhite);
  background-color: var(--primaryColor);
  border-radius: 2px;
  padding: 2px 5px;
}