@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primaryColor: #f15025;
  --mainWhite: #fff;
  --mainBlack: #222;
  --mainGrey: #9e9e9e;
  --mainRed: #d50000;
  --mainGreen: #4caf50;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #222;
  color: var(--mainBlack);
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

h1, h2 {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  color: #222;
  color: var(--mainBlack);
  letter-spacing: 5px;
}


  
.App, 
.costContainer,
.costPage  {
  margin: 0 auto;
}






.form-center {
  display: flex;
  padding: 0 1rem; }
  .form-center .form-group {
    padding: 1rem 0.75rem;
    flex: 0 0 50%; }
    .form-center .form-group .form-control {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid var(--mainGrey);
      border-radius: 0;
      outline: none;
      height: 3rem;
      width: 100%;
      font-size: 16px;
      margin: 0 0 8px 0;
      padding: 0; }
    .form-center .form-group label {
      display: block;
      color: var(--mainGrey);
      font-size: 1rem;
      text-transform: capitalize; }

.item {
  background-color: var(--mainWhite);
  line-height: 1.5rem;
  padding: 10px 20px;
  margin-bottom: 1rem;
  border: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }
  .item .info {
    flex: 0 0 60%;
    display: flex;
    justify-content: space-between; }
    .item .info .expense {
      margin-right: 2rem;
      text-transform: capitalize; }
    .item .info .amount {
      font-weight: 300;
      font-size: 1rem;
      color: var(--mainWhite);
      background-color: var(--primaryColor);
      border-radius: 2px;
      padding: 1px 3px; }

.item:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  -webkit-transform: scale(1.05);
          transform: scale(1.05); }

.clear-btn,
.edit-btn {
  background: none;
  border: none;
  font-size: 1.2rem;
  outline: none;
  cursor: pointer; }

.clear-btn {
  color: var(--mainRed); }

.edit-btn {
  color: var(--mainGreen); }

.list {
  margin: 2rem 0.75rem 0 0.75rem;
  list-style-type: none;
  border: none;
  padding: 0;
  margin: 30px auto; }

.alert {
  padding: 0.75rem 1.25rem;
  color: var(--mainWhite);
  text-align: center;
  text-transform: capitalize;
  width: 90%;
  margin: 2rem auto 0 auto;
  border-radius: 2px;
  text-transform: capitalize; }

.alert-danger {
  background: var(--mainRed); }

.alert-success {
  background: var(--mainGreen); }

.costPage {
  max-width: 1280px;
  width: 90%;
  background: var(--mainWhite);
  padding-bottom: 2rem;
  border-radius: 2px;
  border-style: outset;
  border-color: --mainGrey; }

.btn {
  text-decoration: none;
  color: var(--mainWhite);
  background-color: var(--primaryColor);
  text-align: center;
  letter-spacing: 0.5px;
  font-size: 14px;
  outline: 0;
  border: none;
  border-radius: 2px;
  display: block;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  text-transform: uppercase;
  margin: 0 auto;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: flex;
  align-items: center; }

.btn:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }

.btn-icon {
  margin-left: 0.75rem;
  font-size: 1.2rem; }

@media screen and (min-width: 601px) {
  .costContainer,
  .list,
  .alert {
    width: 85%; }
  .list {
    margin: 2rem auto 0 auto; } }

@media screen and (min-width: 993px) {
  .costContainer,
  .list,
  .alert {
    width: 70%; }
  .list {
    margin: 2rem auto 0 auto; }
  .info {
    flex: 0 0 50%; } }

.total {
  font-weight: 300;
  color: var(--mainWhite);
  background-color: var(--primaryColor);
  border-radius: 2px;
  padding: 2px 5px; }

.footer {
  text-align: center;
  font-family: sans-serif;
  color: grey;
  padding: 20px;
  font-size: 12px;
  bottom: 0;
  width: 100%; }

.navBar {
  text-transform: uppercase;
  padding: 10px;
  border-style: none outset outset none;
  font-weight: 700; }
  .navBar .navItem {
    padding: 10px;
    color: black;
    font-size: 1.5rem;
    font-family: Ubutu;
    text-decoration: none; }
    .navBar .navItem:hover {
      color: gray; }

.skills {
  text-align: center;
  line-height: 65px;
  font-size: 1.6rem;
  font-family: 'Courier New', Courier, monospace;
  padding-top: 50px;
  padding-bottom: 30px; }
  .skills .contact {
    color: orangered;
    margin: 30px; }
  .skills .intro {
    margin: 30px; }


.interestForm {
  flex: 0 0 33% !important; }

.tg-list-item {
  margin: 0 2em;
  padding: 1rem; }

.tgl {
  display: none; }
  .tgl, .tgl:after, .tgl:before,
  .tgl *,
  .tgl *:after,
  .tgl *:before,
  .tgl + .tgl-btn {
    box-sizing: border-box; }
    .tgl::-moz-selection, .tgl:after::-moz-selection, .tgl:before::-moz-selection,
    .tgl *::-moz-selection,
    .tgl *:after::-moz-selection,
    .tgl *:before::-moz-selection,
    .tgl + .tgl-btn::-moz-selection {
      background: none; }
    .tgl::selection, .tgl:after::selection, .tgl:before::selection,
    .tgl *::selection,
    .tgl *:after::selection,
    .tgl *:before::selection,
    .tgl + .tgl-btn::selection {
      background: none; }
  .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
      position: relative;
      display: block;
      content: "";
      width: 50%;
      height: 100%; }
    .tgl + .tgl-btn:after {
      left: 0; }
    .tgl + .tgl-btn:before {
      display: none; }
  .tgl:checked + .tgl-btn:after {
    left: 50%; }

.tgl-skewed + .tgl-btn {
  overflow: hidden;
  -webkit-transform: skew(-10deg);
          transform: skew(-10deg);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-family: sans-serif;
  background: orange; }
  .tgl-skewed + .tgl-btn:after, .tgl-skewed + .tgl-btn:before {
    -webkit-transform: skew(10deg);
            transform: skew(10deg);
    display: inline-block;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    width: 100%;
    text-align: center;
    position: absolute;
    line-height: 2em;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); }
  .tgl-skewed + .tgl-btn:after {
    left: 100%;
    content: attr(data-tg-on); }
  .tgl-skewed + .tgl-btn:before {
    left: 0;
    content: attr(data-tg-off); }
  .tgl-skewed + .tgl-btn:active {
    background: orange; }
    .tgl-skewed + .tgl-btn:active:before {
      left: -10%; }

.tgl-skewed:checked + .tgl-btn {
  background: #47d65d; }
  .tgl-skewed:checked + .tgl-btn:before {
    left: -100%; }
  .tgl-skewed:checked + .tgl-btn:after {
    left: 0; }
  .tgl-skewed:checked + .tgl-btn:active:after {
    left: 10%; }

.policy {
  text-align: center;
  line-height: 65px;
  font-size: 1.6rem;
  font-family: 'Courier New', Courier, monospace;
  padding-top: 50px;
  padding-bottom: 30px; }

.appstore {
  width: 10rem;
  margin: 2rem; }

.applogo {
  width: 5rem;
  border-radius: 10%;
  border: 0.5px solid white;
  margin: 2rem; }

.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 4rem; }

.screenshot {
  width: 15rem; }
  .screenshot:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition-duration: 1s;
            transition-duration: 1s; }

.sudoku {
  background-color: lightsalmon;
  text-align: center; }
  .sudoku_title {
    padding-top: 1.5rem; }

.sudokuzen {
  background-color: white;
  text-align: center; }
  .sudokuzen_title {
    padding-top: 1.5rem; }

.container {
  text-align: center; }

.end-container {
  display: inline; }

.appstore_fi {
  width: 14rem;
  margin: 2rem; }

.screenshot_fi {
  width: 33rem;
  margin: 3rem; }

h3 {
  padding: 1rem; }

.seperation {
  margin-top: 5rem; }

.image_1 {
  width: 30rem;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.image_2 {
  width: 50rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.big-background {
  background-color: #f5f4f4;
  padding-top: 2.5rem;
  padding-bottom: 1rem; }

.pos_block {
  display: flex;
  margin: 7rem 10rem;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }

.other_image {
  width: 30rem;
  border-radius: 2%;
  border: 0.5px solid #F09B34; }

.bill-image {
  width: 15rem;
  border-radius: 2%;
  border: 0.5px solid #F09B34; }

#box {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Raleway';
  font-size: 2.5rem; }

.gradient-border {
  --borderWidth: 3px;
  background: #1D1F20;
  position: relative;
  border-radius: var(--borderWidth); }

.gradient-border:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: -webkit-linear-gradient(30deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
          animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%; }

@-webkit-keyframes animatedgradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

.pos-foot {
  text-align: center; }

.link {
  text-decoration: none; }

.link:hover {
  color: forestgreen;
  background-color: greenyellow;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  -webkit-transition-duration: 1s;
          transition-duration: 1s; }

.link:active {
  color: orange; }

.link:visited {
  color: blueviolet; }

h1.pos {
  color: #F09B34; }

h1.pay-daily {
  color: blueviolet; }

h1.neon {
  color: #f37055; }

.pos-container {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center; }

.pos-small-title {
  text-align: left;
  padding-left: 5rem;
  text-decoration: underline; }

.no-note {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  grid-gap: 5rem;
  gap: 5rem;
  margin: 5rem;
  flex-wrap: wrap;
  padding-top: 3rem;
  padding-bottom: 3rem; }

@media only screen and (max-width: 720px) {
  .pos_block {
    margin: 1rem; } }

@media only screen and (max-width: 500px) {
  .other_image {
    width: 80%; }
  .image_1 {
    width: 80%; }
  .bill-image {
    width: 50%; } }

.rotate {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.pos-h4 {
  text-align: center;
  margin: 3rem; }

.galaxygallery {
  background-color: white;
  text-align: center; }
  .galaxygallery_title {
    padding-top: 1.5rem; }

