.footer {
    text-align: center;
    font-family: sans-serif;
    color: grey;
    padding: 20px;
    font-size: 12px;
    bottom: 0;
    width: 100%;
}


