.container {
    text-align: center;
}
.end-container {
    display: inline;
}

.appstore_fi {
    width: 14rem;
    margin: 2rem;
}

.screenshot_fi{
    width: 33rem;
    margin: 3rem;
}



h3 {
    padding: 1rem
}

.seperation {
    margin-top: 5rem;
}

